<template>
  <div>
    <b-row>
      <b-col
        v-if="displayPlansDescription"
        class="mb-2"
      >
        {{ $t('plansDescription') }}
      </b-col>
      <!--<b-col class="mb-2 d-flex align-items-center justify-content-center">
        <h6 class="mr-1 mb-0">
          {{ $t('Monthly') }}
        </h6>
        <b-form-checkbox
          v-model="yearlyPeriodToggle"
          :disabled="disableMonthlyPeriod"
          switch
        />
        <h6 class="ml-50 mb-0">
          {{ $t('Annually') }}
        </h6>
      </b-col>-->
    </b-row>
    <table class="table-plans">
      <thead class="table-plans__thead">
        <tr class="table-plans__tr">
          <td class="table-plans__td" />
          <td
            v-for="headerItem in subscriptionsJson.header"
            :key="headerItem.plan"
            class="table-plans__td"
          >
            <div class="font-medium-1 font-weight-bolder text-primary text-uppercase">
              <!--{{ $t(`_subscriptions.${headerItem.plan}`) }}-->
              {{ headerItem.plan }}
            </div>
            <div class="font-medium-1 font-weight-bolder mt-50">
              {{ getPlanRenderPrice(headerItem.plan) }} / {{ $t('mo') }}
            </div>
            <div>
              {{ yearlyPeriodToggle ? $t('billed annually') : $t('billed monthly') }}
            </div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="featuresItem in subscriptionsJson.features"
          :key="featuresItem.feature"
          class="table-plans__tr"
        >
          <td class="table-plans__td">
            <div class="d-flex align-items-center">
              <div>
                {{ $t(`_subscriptions.${featuresItem.feature}`) }}
              </div>
              <feather-icon
                v-if="featuresItem.tooltip"
                v-b-tooltip.hover.bottom="$t(`_subscriptions.${featuresItem.tooltip}`)"
                icon="InfoIcon"
                size="16"
                class="flex-shrink-0 text-primary ml-50"
              />
            </div>
          </td>
          <td
            v-for="(featuresItemValue, featuresItemValueIndex) in featuresItem.values"
            :key="featuresItem.feature + '_' + featuresItemValueIndex + '_'+ featuresItemValue"
            class="table-plans__td"
          >
            <template v-if="typeof featuresItemValue === 'boolean'">
              <feather-icon
                v-if="featuresItemValue"
                icon="CheckIcon"
                size="16"
                class="text-primary"
              />
            </template>
            <template v-else-if="featuresItemValue !== null && !isNaN(+featuresItemValue) && (typeof +featuresItemValue === 'number')">
              {{ featuresItemValue }}
            </template>
            <template v-else-if="typeof featuresItemValue === 'string'">
              <!--{{ $t(`_subscriptions.${featuresItemValue}`) }}-->
              {{ featuresItemValue }}
            </template>
          </td>
        </tr>
      </tbody>
      <tfoot class="table-plans__tfoot">
        <tr class="table-plans__tr">
          <td class="table-plans__td" />
          <td
            v-for="headerItem in subscriptionsJson.header"
            :key="headerItem.plan"
            class="table-plans__td"
          >
            <template v-if="hasActiveSubscription">
              <b-button
                v-if="getPlanIdByName(headerItem.plan) !== activePlanId"
                variant="primary"
                size="sm"
                @click="$emit('selectSubscription', getPlanIdByName(headerItem.plan), yearlyPeriodToggle ? 'yearly' : 'monthly')"
              >
                {{ subscribeButtonText(getPlanIdByName(headerItem.plan)) }}
              </b-button>
              <b-button
                v-if="getPlanIdByName(headerItem.plan) === activePlanId"
                size="sm"
                @click="$emit('cancelSubscription')"
              >
                {{ $t('Cancel Subscription') }}
              </b-button>
            </template>
            <b-button
              v-else
              size="sm"
              variant="primary"
              @click="$emit('selectSubscription', getPlanIdByName(headerItem.plan), yearlyPeriodToggle ? 'yearly' : 'monthly')"
            >
              {{ subscribeButtonText() }}
            </b-button>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  // BFormCheckbox,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import subscriptionsJson from '@/subscriptions.json'
import { mapGetters } from 'vuex'

export default {
  name: 'Plans',
  components: {
    BRow,
    BCol,
    // BFormCheckbox,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    displayPlansDescription: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  data() {
    return {
      yearlyPeriodToggle: false,
      subscriptionsJson: null,
    }
  },
  computed: {
    ...mapGetters({
      subscription: 'billing/subscription',
      billingConfig: 'billing/billingConfig',
    }),
    hasActiveSubscription() {
      return (!['Inactive', 'Canceled'].includes(this.subscriptionStatus))
    },
    activePlanId() {
      return this.subscription && this.subscription.productDetails.id ? this.subscription.productDetails.id : 0
    },
    subscriptionStatus() {
      return this.subscription ? this.subscription.status : 'Inactive'
    },
    trialActive() {
      return this.subscription ? this.subscription.trialActive : false
    },
    // activePlanInterval() {
    //   return this.subscription && this.subscription.interval ? this.subscription.interval : 'yearly'
    // },
    // disableMonthlyPeriod() {
    //   const activePlanPeriodIsYearly = this.activePlanInterval && this.activePlanInterval === 'yearly'
    //   const notInactive = this.subscriptionStatus !== 'Inactive'
    //   return activePlanPeriodIsYearly && notInactive && !this.trialActive
    // },
  },
  created() {
    this.subscriptionsJson = subscriptionsJson
  },
  methods: {
    getPlanRenderPrice(planName) {
      if (!this.billingConfig) {
        return ''
      }

      const plan = this.billingConfig.plans.find(i => i.name === planName)
      if (!plan) {
        return ''
      }

      const { yearly } = plan.periods
      const { monthly } = plan.periods

      return this.yearlyPeriodToggle
        ? `${yearly.currencySymbol}${yearly.monthlyPay}`
        : `${monthly.currencySymbol}${monthly.monthlyPay}`
    },
    getPlanIdByName(planName) {
      if (!this.billingConfig) {
        return 0
      }

      const plan = this.billingConfig.plans.find(i => i.name === planName)
      if (!plan) {
        return 0
      }

      return plan.id
    },
    subscribeButtonText(planId) {
      if (this.subscriptionStatus === 'Inactive') {
        return this.$t('Start Free Trial')
      }

      if (this.subscriptionStatus === 'Canceled') {
        return this.$t('Subscribe')
      }

      return planId > this.activePlanId ? this.$t('Upgrade') : this.$t('Downgrade')
    },
  },
}
</script>

<style lang="sass">
  @import '~@/assets/sass/table-plans.sass'
</style>
