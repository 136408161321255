<template>
  <b-modal
    id="modal-stripe"
    :visible="visible"
    :title="title"
    centered
    hide-footer
    @hide="onHideModal"
  >
    <div
      v-if="isLoading"
      class="d-flex justify-content-center"
    >
      <b-spinner variant="primary" />
    </div>
    <validation-observer
      v-show="!isLoading"
      ref="vo"
    >
      <b-form @submit.prevent="onSubmitForm">
        <h6 class="mt-50 mb-1">
          {{ $t('Billing Information') }}
        </h6>
        <b-form-group :label="$t('Country')">
          <b-form-select
            v-model="country"
            :options="optionsCountries"
            required
            @change="resolveValidationRules"
          />
        </b-form-group>
        <b-form-group :label="$t('Cardholder Name')">
          <validation-provider
            #default="{ errors, failedRules }"
            name="cardholder_name"
            rules="required"
          >
            <b-form-input
              v-model="cardholderName"
              trim
              :state="errors.length ? false : null"
            />
            <small class="text-danger">
              <template v-if="failedRules.required">{{ $t('validationErrorRequiredCardholderName') }}</template>
              <template v-else>{{ errors[0] }}</template>
            </small>
          </validation-provider>
        </b-form-group>
        <b-form-group :label="$t('Billing Email Address')">
          <validation-provider
            #default="{ errors, failedRules }"
            name="billing_email"
            rules="required|email"
          >
            <b-form-input
              v-model="billingEmailAddress"
              trim
              :state="errors.length ? false : null"
            />
            <small class="text-danger">
              <template v-if="failedRules.required">{{ $t('validationErrorRequiredBillingEmail') }}</template>
              <template v-else-if="failedRules.email">{{ $t('validationErrorEmailBillingEmail') }}</template>
              <template v-else>{{ errors[0] }}</template>
            </small>
          </validation-provider>
        </b-form-group>
        <b-row
          v-if="displayCoupon"
          class="mb-2"
        >
          <b-col>
            <b-form-group
              class="mb-0"
              :label="$t('couponLabel')"
            >
              <b-form-input
                v-model="coupon"
                trim
                :disabled="isCouponApplying || isCouponApplied"
                :placeholder="$t('couponPlaceholder')"
                :state="isCouponValid"
                @input="onInputCoupon"
              />
              <small
                v-if="couponErrorMessage"
                class="text-danger"
              >
                {{ couponErrorMessage }}
              </small>
              <small v-if="isCouponApplied">
                <span style="text-decoration: line-through">{{ chosenPlanCurrencySymbol }}{{ getFormattedPrice(chosenPlanMonthlyPay) }}</span>
                >
                {{ chosenPlanCurrencySymbol }}{{ getFormattedPrice(chosenPlanMonthlyPayWithCoupon) }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="auto"
            class="pt-1"
          >
            <b-button
              class="mt-1"
              variant="primary"
              :disabled="isCouponApplying || isCouponApplied"
              @click="onClickApplyCoupon"
            >
              <b-spinner
                v-if="isCouponApplying"
                class="d-flex"
                small
              />
              <template v-else>
                {{ $t('couponButtonName') }}
              </template>
            </b-button>
          </b-col>
        </b-row>
        <h6 class="mt-3 mb-1">
          {{ $t('Payment Information') }}
        </h6>
        <b-form-group :label="$t('Card')">
          <stripe-element-card
            ref="stripe"
            :hide-postal-code="hidePostalCode"
            :pk="stripePk"
            @token="submitToken"
            @error="handleStripeError"
            @element-change="cardInfoChange"
            @element-ready="isLoading = false"
          />
        </b-form-group>
        <b-row class="mt-2">
          <b-col class="d-flex align-items-center">
            <img
              src="@/assets/images/icons/powered-by-stripe.svg"
              alt=""
            >
          </b-col>
          <b-col cols="auto">
            <b-button
              variant="outline-primary"
              class="mr-1"
              @click="onClickCancel"
            >
              {{ $t('Cancel') }}
            </b-button>
            <b-button
              variant="primary"
              type="submit"
              :disabled="!isFormCompleted"
            >
              {{ buttonText }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BButton,
  BFormInput,
  BFormSelect,
  BForm,
  BSpinner,
  BRow,
  BCol,
  BFormGroup,
} from 'bootstrap-vue'
import { StripeElementCard } from '@vue-stripe/vue-stripe'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, email } from '@validations'
import { mapGetters } from 'vuex'
import mixinFormatter from '@/mixins/formatter'

import GET_COUPON_INFO from '@/gql/query/billing/getCouponInfo.gql'

export default {
  name: 'ModalStripe',
  components: {
    BModal,
    BButton,
    BFormInput,
    BFormSelect,
    BForm,
    StripeElementCard,
    BSpinner,
    BRow,
    BCol,
    BFormGroup,

    // validations
    ValidationObserver,
    ValidationProvider,
  },
  mixins: [
    mixinFormatter,
  ],
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    title: {
      type: String,
      required: true,
      default: '',
    },
    buttonText: {
      type: String,
      required: true,
      default: '',
    },
    displayCoupon: {
      type: Boolean,
      required: true,
      default: true,
    },
    chosenPlanMonthlyPay: {
      type: Number,
      required: true,
      default: 0,
    },
    chosenPlanCurrencySymbol: {
      type: String,
      required: true,
      default: '$',
    },
  },
  data() {
    return {
      isLoading: true,
      cardInfoCompleted: false,

      country: 'US',
      cardholderName: '',
      billingEmailAddress: '',

      validationRules: {
        UA: {
          requiredFields: [],
          hidePostalCode: true,
        },
        US: {
          requiredFields: [],
          hidePostalCode: false,
        },
        default: {
          requiredFields: [],
          hidePostalCode: true,
        },
      },

      coupon: '',
      isCouponValid: null,
      isCouponApplying: false,
      isCouponApplied: false,
      discountPercentage: 0,
      couponErrorMessage: '',

      stripePk: process.env.VUE_APP_STRIPE_PK,

      // validation rules
      required,
      email,
    }
  },
  computed: {
    ...mapGetters({
      countries: 'dictionaries/countries',
      paymentMethods: 'billing/paymentMethods',
    }),
    hidePostalCode() {
      let validationRules = this.validationRules[this.country]

      if (!validationRules) {
        validationRules = this.validationRules.default
      }

      return validationRules.hidePostalCode
    },
    isFormCompleted() {
      return this.country && this.cardholderName && this.billingEmailAddress && this.cardInfoCompleted
    },
    optionsCountries() {
      const unshift = ['US', 'CA']

      const sorted = this.countries
        .filter(i => !unshift.find(j => j === i.value))
        .sort((a, b) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()))

      return [
        ...unshift.map(i => this.countries.find(j => j.value === i)),
        ...sorted,
      ]
    },
    chosenPlanMonthlyPayWithCoupon() {
      if (this.discountPercentage) {
        return this.chosenPlanMonthlyPay - (this.chosenPlanMonthlyPay * (this.discountPercentage / 100))
      }
      return this.chosenPlanMonthlyPay
    },
    customerData() {
      if (!this.paymentMethods) {
        return {}
      }

      if (!this.paymentMethods.customer) {
        return {}
      }

      return this.paymentMethods.customer
    },
  },
  watch: {
    visible(newValue) {
      if (newValue) {
        this.country = this.customerData.country || 'US'
        this.cardholderName = this.customerData.name || ''
        this.billingEmailAddress = this.customerData.billingEmailAddress || ''
      }
    },
  },
  methods: {
    // separate list of fields and validation rules for every country, should be like common npm package
    resolveValidationRules() {
      this.$refs.stripe.clear()
      this.$refs.stripe.update()
    },
    cardInfoChange(e) {
      this.cardInfoCompleted = e.complete && !e.empty
    },
    handleStripeError() {
      this.isLoading = false
    },
    onSubmitForm() {
      this.$refs.vo.validate().then(async isValid => {
        if (isValid) {
          this.$refs.stripe.submit()
          this.isLoading = true
        }
      })
    },
    submitToken(session) {
      this.$emit('success', {
        country: this.country,
        cardholderName: this.cardholderName,
        billingEmailAddress: this.billingEmailAddress,
        token: session.id,
        coupon: this.isCouponValid ? this.coupon : null,
      })

      this.clearData()
    },
    async onClickApplyCoupon() {
      if (!this.coupon.length) {
        this.isCouponValid = false
        return
      }

      this.isCouponApplying = true

      try {
        const response = await this.$apollo.query({
          query: GET_COUPON_INFO,
          variables: {
            coupon: this.coupon,
          },
        })

        const coupon = response.data.getCouponInfo
        if (coupon.valid) {
          this.coupon = coupon.id
          this.discountPercentage = coupon.percentOff
          this.isCouponApplied = true
          this.isCouponValid = true
        } else {
          this.isCouponValid = false
          if (coupon.maxRedemptions !== null) {
            this.couponErrorMessage = this.$t('couponErrorMaxRedemptions')
          } else if (coupon.redeemBy !== null) {
            this.couponErrorMessage = this.$t('couponErrorRedeemBy')
          } else {
            this.couponErrorMessage = this.$t('couponErrorDefault')
          }
        }
      } catch (error) {
        this.couponErrorMessage = error.graphQLErrors[0].id
        this.isCouponValid = false
      } finally {
        this.isCouponApplying = false
      }
    },
    onHideModal() {
      this.$emit('cancel')
      this.clearData()
    },
    onClickCancel() {
      this.$emit('cancel')
      this.clearData()
    },
    onInputCoupon() {
      this.couponErrorMessage = ''
      this.isCouponValid = null
    },
    clearData() {
      this.country = 'US' // default one
      this.cardholderName = ''
      this.billingEmailAddress = ''

      this.coupon = ''
      this.isCouponValid = null
      this.isCouponApplying = false
      this.isCouponApplied = false
      this.discountPercentage = 0
      this.couponErrorMessage = ''

      this.$refs.stripe.clear()
    },
  },
}
</script>

<style lang="sass">
  @import '@core/scss/base/bootstrap-extended/_variables.scss'

  #modal-stripe
    .modal-dialog
      max-width: 530px

  #stripe-element-errors
    color: $red
    font-size: $small-font-size
</style>
