<template>
  <b-modal
    :visible="visible"
    :title="$t('Cancel Subscription')"
    centered
    hide-footer
    @hide="$emit('cancel')"
  >
    <b-form @submit.prevent="onSubmitForm">
      <div class="mt-1">
        {{ $t('We are sad to see you go! If you proceed, your subscription will be canceled and will end on') }} {{ endDate }}.
      </div>

      <div class="mt-1">
        <span>{{ $t('If you have any questions about our plans or your subscription, please contact us any time at') }}</span>
        <span class="text-primary"> support@sporkinc.com</span>
      </div>

      <div class="mt-2 mb-1">
        {{ $t('Reason for canceling') }}:
      </div>

      <b-form-group>
        <b-form-radio
          v-model="reason"
          value="A"
          :disabled="isSubmitting"
        >
          {{ $t('No longer need this service') }}
        </b-form-radio>
        <b-form-radio
          v-model="reason"
          value="B"
          :disabled="isSubmitting"
          class="mt-50"
        >
          {{ $t('Switching to another product') }}
        </b-form-radio>
        <b-form-radio
          v-model="reason"
          value="C"
          :disabled="isSubmitting"
          class="mt-50"
        >
          {{ $t('Too Expensive') }}
        </b-form-radio>
        <b-form-radio
          v-model="reason"
          value="D"
          :disabled="isSubmitting"
          class="mt-50"
        >
          {{ $t('Technical issues') }}
        </b-form-radio>
        <b-form-radio
          v-model="reason"
          value="E"
          :disabled="isSubmitting"
          class="mt-50"
        >
          {{ $t('Insufficient features') }}
        </b-form-radio>
        <b-form-radio
          v-model="reason"
          value="F"
          :disabled="isSubmitting"
          class="mt-50"
        >
          {{ $t('Other') }}
        </b-form-radio>
      </b-form-group>

      <div class="mt-2 text-right">
        <b-button
          variant="outline-primary"
          class="mr-1"
          :disabled="isSubmitting"
          @click="$emit('cancel')"
        >
          {{ $t('Cancel') }}
        </b-button>
        <b-button
          variant="primary"
          type="submit"
          class="d-inline-flex"
          :disabled="isSubmitting"
        >
          <b-spinner
            v-if="isSubmitting"
            small
          />
          <template v-else>
            {{ $t('Cancel Subscription') }}
          </template>
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormRadio,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import CANCEL_SUBSCRIPTION from '@/gql/mutation/billing/cancelSubscription.gql'

export default {
  name: 'ModalCancelSubscription',
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormRadio,
    BButton,
    BSpinner,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    endDate: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      reason: '',
      isSubmitting: false,
    }
  },
  computed: {
    ...mapGetters({
      restaurant: 'restaurant/restaurant',
    }),
  },
  methods: {
    ...mapActions({
      getSubscription: 'billing/getSubscription',
      getInvoices: 'billing/getInvoices',
    }),
    async onSubmitForm() {
      this.isSubmitting = true
      try {
        await this.$apollo.mutate({
          mutation: CANCEL_SUBSCRIPTION,
          variables: {
            organizationId: this.restaurant.organization.id,
          },
        })

        await Promise.all([
          this.getSubscription(),
          this.getInvoices(),
        ])

        this.$emit('cancel')
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: Array.isArray(error.graphQLErrors[0].message)
              ? error.graphQLErrors[0].message[0]
              : error.graphQLErrors[0].message,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isSubmitting = false
      }
    },
  },
}
</script>
