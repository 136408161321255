<template>
  <b-modal
    id="modal-downgrade"
    v-b-modal.modal-xl
    :visible="visible"
    :title="title"
    centered
    hide-footer
    @hide="onHideModal"
  >
    <template v-if="step === 0">
      <h4>{{ $t('User Accounts') }}</h4>
      <p class="mt-1">
        <template v-if="applicationState.targetPlan.name === 'Basic'">
          {{ $t('planBasicUsers') }}
        </template>
        <template v-if="applicationState.targetPlan.name === 'Standard'">
          {{ $t('planStandardUsers', { amount: applicationState.targetPlan.restrictions.collaboratorsAmount }) }}
        </template>
      </p>
      <div>
        <b-form-checkbox
          v-for="user in applicationState.users"
          :key="user.id"
          v-model="remainingUsers"
          class="modal-downgrade__checkbox my-25"
          :disabled="user.role.toLowerCase() === 'owner'"
          :value="user.id"
        >
          <div class="d-flex justify-content-between">
            <div v-if="user.userFirstName || user.userLastName">
              {{ user.userFirstName }} {{ user.userLastName }}
            </div>
            <div v-else>
              {{ user.userEmail }}
            </div>
            <div>{{ $t(`_usersRole.${user.role}`) }}</div>
          </div>
        </b-form-checkbox>
      </div>
    </template>
    <template v-if="step === 1">
      <h4>{{ $t('Menus') }}</h4>
      <p class="mt-1">
        <template v-if="applicationState.targetPlan.name === 'Basic'">
          {{ $t('planBasicMenus') }}
        </template>
        <template v-if="applicationState.targetPlan.name === 'Standard'">
          {{ $t('planStandardMenus', { amount: applicationState.targetPlan.restrictions.menusPerRestaurantAmount }) }}
        </template>
      </p>
      <div>
        <b-form-checkbox
          v-for="menu in applicationState.menus"
          :key="menu.id"
          v-model="remainingMenus"
          class="modal-downgrade__checkbox my-25"
          :value="menu.id"
        >
          <div class="d-flex justify-content-between">
            <div>{{ menu.name }}</div>
          </div>
        </b-form-checkbox>
      </div>
    </template>
    <template v-if="step === 2">
      <h4>{{ $t('Images and Videos') }}</h4>
      <p class="mt-1">
        <template v-if="applicationState.targetPlan.name === 'Basic'">
          {{ $t('planBasicImages') }}
        </template>
        <template v-if="applicationState.targetPlan.name === 'Standard'">
          {{ $t('planStandardMedia', { amount: applicationState.targetPlan.restrictions.menuItemMediaAmount }) }}
          {{ $t('Videos and extra images will be removed from those Menu Items that contain them.') }}
        </template>
      </p>
    </template>
    <template v-if="step === 3">
      <h4>{{ $t('QR Code zones') }}</h4>
      <p class="mt-1">
        <template v-if="applicationState.targetPlan.name === 'Basic'">
          {{ $t('planBasicZones') }}
        </template>
        <template v-if="applicationState.targetPlan.name === 'Standard'">
          {{ $t('planStandardZones', { amount: applicationState.targetPlan.restrictions.zonesAmount }) }}
        </template>
      </p>
      <div>
        <b-form-checkbox
          v-for="zone in applicationState.zones"
          :key="zone.id"
          v-model="remainingZones"
          class="modal-downgrade__checkbox my-25"
          :value="zone.id"
        >
          <div class="d-flex justify-content-between">
            <div>{{ zone.name }}</div>
          </div>
        </b-form-checkbox>
      </div>
    </template>
    <template v-if="step === 4">
      <h4>{{ $t('Languages') }}</h4>
      <p class="mt-1">
        <template v-if="applicationState.targetPlan.name === 'Basic'">
          {{ $t('planBasicLanguages') }}
        </template>
        <template v-if="applicationState.targetPlan.name === 'Standard'">
          {{ $t('planStandardLanguages', { amount: applicationState.targetPlan.restrictions.menuTranslationsAmount - 1 }) }}
        </template>
      </p>
      <div v-if="applicationState.targetPlan.name === 'Standard'">
        <b-form-checkbox
          v-for="language in applicationState.languages"
          :key="language.shortName"
          v-model="remainingLanguages"
          class="modal-downgrade__checkbox my-25"
          :value="language.shortName"
        >
          <div class="d-flex justify-content-between">
            <div>{{ language.longName }}</div>
          </div>
        </b-form-checkbox>
      </div>
    </template>
    <template v-if="step === 5">
      <h4>{{ $t('Up-Sell and Cross-Sell') }}</h4>
      <p class="mt-1">
        {{ $t('crossSellNotAvailable', { name: applicationState.targetPlan.name }) }}
      </p>
    </template>
    <div class="d-flex justify-content-between mt-2">
      <b-button
        variant="outline-primary"
        @click="onClickCancel"
      >
        {{ $t('Cancel Downgrade') }}
      </b-button>
      <div>
        <b-button
          variant="primary"
          class="mr-50"
          :disabled="step === 0"
          @click="stepBackward"
        >
          &lt; {{ $t('Back') }}
        </b-button>
        <b-button
          variant="primary"
          :disabled="isAcceptDisabled"
          @click="stepForward"
        >
          {{ $t('Accept') }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'

export default {
  name: 'ModalDowngrade',
  components: {
    BModal,
    BButton,
    BFormCheckbox,
  },
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: true,
      default: 'Downgrade to Basic Plan',
    },
    applicationState: {
      type: Object,
      required: true,
      default: () => ({
        targetPlan: {},
        targetPeriod: 'yearly',
        users: [],
        menus: [],
        zones: [],
        languages: [],
      }),
    },
  },
  data() {
    return {
      step: 0,

      remainingUsers: [],
      remainingMenus: [],
      remainingZones: [],
      remainingLanguages: [],

      usersMinSelection: 1,
      usersMaxSelection: 0,
      menusMinSelection: 1,
      menusMaxSelection: 0,
      zonesMinSelection: 1,
      zonesMaxSelection: 0,
      languagesMinSelection: 0,
      languagesMaxSelection: 0,
    }
  },
  computed: {
    isAcceptUsers() {
      return !this.applicationState.users.length
        || (
          this.remainingUsers.length >= this.usersMinSelection
            && this.remainingUsers.length <= this.usersMaxSelection
        )
    },
    isAcceptMenus() {
      return !this.applicationState.menus.length
        || (
          this.remainingMenus.length >= this.menusMinSelection
            && this.remainingMenus.length <= this.menusMaxSelection
        )
    },
    isAcceptZones() {
      return !this.applicationState.zones.length
        || (
          this.remainingZones.length >= this.zonesMinSelection
            && this.remainingZones.length <= this.zonesMaxSelection
        )
    },
    isAcceptLanguages() {
      return !this.applicationState.languages.length
        || (
          this.remainingLanguages.length >= this.languagesMinSelection
            && this.remainingLanguages.length <= this.languagesMaxSelection
        )
    },
    isAcceptDisabled() {
      return (this.step === 0 && !this.isAcceptUsers)
        || (this.step === 1 && !this.isAcceptMenus)
        || (this.step === 3 && !this.isAcceptZones)
        || (this.step === 4 && !this.isAcceptLanguages)
    },
  },
  watch: {
    applicationState: {
      handler(newValue) {
        if (newValue.targetPlan && newValue.targetPlan.restrictions) {
          this.usersMaxSelection = newValue.targetPlan.restrictions.collaboratorsAmount
          this.menusMaxSelection = newValue.targetPlan.restrictions.menusPerRestaurantAmount
          this.zonesMaxSelection = newValue.targetPlan.restrictions.zonesAmount

          const { menuTranslationsAmount } = newValue.targetPlan.restrictions
          this.languagesMinSelection = menuTranslationsAmount - 1 // minus default language
          this.languagesMaxSelection = menuTranslationsAmount - 1 // minus default language

          const owner = newValue.users.find(i => i.role.toLowerCase() === 'owner')
          if (owner) {
            this.remainingUsers = [...this.remainingUsers, owner.id]
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    stepForward() {
      if (this.step >= 5) {
        this.$emit('changePlan', this.applicationState.targetPlan.id, this.applicationState?.targetPeriod, {
          remainingUsers: this.remainingUsers,
          remainingMenus: this.remainingMenus,
          remainingZones: this.remainingZones,
          remainingLanguages: this.remainingLanguages,
        })
      }

      this.step += 1
    },
    stepBackward() {
      if (this.step > 0) {
        this.step -= 1
      }
    },
    resetModalData() {
      this.step = 0

      this.remainingUsers = []
      this.remainingMenus = []
      this.remainingZones = []
      this.remainingLanguages = []
    },
    onHideModal() {
      this.$emit('cancel')
      this.resetModalData()
    },
    onClickCancel() {
      this.$emit('cancel')
      this.resetModalData()
    },
  },
}
</script>

<style lang="sass">
 #modal-downgrade
   .modal-downgrade__checkbox
     .custom-control-label
       width: 100%
</style>
