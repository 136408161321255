<template>
  <div>
    <b-card>
      <div
        v-if="isFetching"
        class="d-flex justify-content-center"
      >
        <b-spinner variant="primary" />
      </div>
      <template v-else>
        <div v-show="isSubscriptionActive || isSubscriptionCanceled">
          <b-row class="mb-2 mt-1">
            <b-col md="8">
              <h5>{{ $t('Subscription Plan') }}</h5>
              <div v-if="subscription.cancelAtPeriodEnd && !subscription.hasUpcomingSubscription">
                {{ $t('Your subscription ends on') }} {{ currentPeriodEndDate }}
              </div>
              <div v-if="subscription.hasUpcomingSubscription && subscription.upcomingSubscription">
                <div>
                  {{ $t('subscriptionEnds', { date: currentPeriodEndDate }) }}
                </div>
                <div>
                  {{ $t(
                    'subscriptionSwitch',
                    {
                      planName: getPlanNameAfterSwitchByStipeProductId(),
                      planInterval: subscription.upcomingSubscription.interval
                    }
                  ) }}
                </div>
              </div>
              <div v-if="isSubscriptionActive && subscription.trialActive">
                {{ $t('subscriptionBegin', { date: trialPeriodEndDate }) }}
              </div>
            </b-col>
            <b-col
              md="4"
              class="text-right"
            >
              <b-badge
                :variant="subscriptionStatusBadgeVariant"
                pill
              >
                {{ subscriptionStatus }}
              </b-badge>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col
              md="8"
              class="d-flex align-items-end align-self-center"
            >
              <h2 class="m-0 line-height-1">
                {{ subscription.productDetails.name }}
              </h2>
              <small class="ml-25 text-secondary">
                ({{ $t('per restaurant per month paid') }} {{ $t(subscription.interval) }})
              </small>
            </b-col>
            <b-col
              md="4"
              class="text-right"
            >
              <h2 class="m-0 line-height-1">{{ getCurrencySymbolByName(subscription.currency) }}{{
                subscription.coupon && subscription.coupon.percentOff
                  ? getFormattedPrice(monthlyPay - (monthlyPay / 100 * subscription.coupon.percentOff))
                  : monthlyPay
              }}</h2>
            </b-col>
          </b-row>
        </div>
        <div v-if="isSubscriptionInactive">
          <h3 class="mb-2">
            {{ $t('You are not subscribed') }}
          </h3>
          <i18n
            path="subscriptionInactiveMessage"
            tag="small"
            class="d-block mb-2"
          >
            <template v-slot:slot>
              <br><br>
            </template>
          </i18n>
          <plans
            :display-plans-description="false"
            @selectSubscription="selectSubscription"
          />
        </div>
        <div v-if="isSubscriptionActive || isSubscriptionCanceled">
          <b-col
            md="6"
            class="text-left px-0"
          >
            <b-button
              variant="primary"
              @click="isModalChangePlan = true"
            >
              {{ isSubscriptionCanceled ? $t('Reactivate Your Subscription') : $t('Change Plan') }}
            </b-button>
          </b-col>
          <h5 class="mt-3 mb-1">
            {{ $t('Pricing') }}
          </h5>
          <b-row
            class="border mx-0 p-1"
            align-h="around"
          >
            <b-col md="2">
              <span>{{ $t('Per') }} {{ $t(intervalUnit) }}</span>
              <h5 class="pr-1">
                {{ getCurrencySymbolByName(subscription.currency) }}{{ getFormattedPrice(subscription.amount) }}
              </h5>
            </b-col>
            <b-col md="2">
              <span>{{ $t('Cycle') }}</span>
              <h5 class="pr-1">
                {{ $t(subscription.interval) }}
              </h5>
            </b-col>
            <b-col md="2">
              <span>{{ $t('Restaurants') }}</span>
              <h5 class="pr-1">
                {{ subscription.quantity }}
              </h5>
            </b-col>
            <b-col
              md="4"
              class="pl-3"
            >
              <span>{{ $t('Next Payment') }}</span>
              <h5 class="pr-1">
                {{ currentPeriodEndDate }}
              </h5>
            </b-col>
            <b-col md="2">
              <span>{{ $t('Total') }}</span>
              <h5 class="pr-1">
                {{ getCurrencySymbolByName(subscription.currency) }}{{ getFormattedPrice(subscription.amount) }}
              </h5>
            </b-col>
          </b-row>
          <template v-if="subscription.addons && subscription.addons.length">
            <h5 class="mt-3 mb-1">
              {{ $t('Additional services pricing') }}
            </h5>
            <b-table
              :fields="['service_name', 'monthly_cost', 'next_payment', 'total']"
              :items="subscription.addons"
              :bordered="true"
            >
              <template #head(service_name)>
                {{ $t('addOnsServiceName') }}
              </template>
              <template #head(monthly_cost)>
                {{ $t('addOnsMonthlyCost') }}
              </template>
              <template #head(next_payment)>
                {{ $t('Next Payment') }}
              </template>
              <template #head(total)>
                {{ $t('Total') }}
              </template>
              <template #cell(service_name)="data">
                {{ getAddonNameToRender(data.item.name) }}
              </template>
              <template #cell(monthly_cost)="data">
                {{ data.item.currencySymbol }}{{ getFormattedPrice(data.item.monthlyPay) }}
              </template>
              <template #cell(next_payment)="data">
                {{ getFormattedDate(data.item.currentPeriodEndDate) }}
              </template>
              <template #cell(total)="data">
                {{ data.item.currencySymbol }}{{ getFormattedPrice(data.item.total) }}
              </template>
            </b-table>
          </template>
          <h5 class="mb-1 mt-3 mx-0">
            {{ $t('Payment Methods') }}
          </h5>
          <b-row
            v-for="paymentMethod in paymentMethods ? paymentMethods.paymentMethods : []"
            :key="paymentMethod.id"
            align-v="center"
            class="border mx-0 p-2"
          >
            <b-col md="2">
              <img
                width="38px"
                :src="require(`@/assets/images/billing/${paymentMethod.brand}.png`)"
                alt=""
              >
            </b-col>
            <b-col
              md="2"
              class="text-center"
            >
              <h5 style="margin: 0">
                **** {{ paymentMethod.last4 }}
              </h5>
            </b-col>
            <b-col
              md="4"
              class="text-center"
            >
              <h6 class="m-0 text-muted">
                {{ $t('Expires') }}: {{ paymentMethod.expirationMonth }}/{{ paymentMethod.expirationYear }}
              </h6>
            </b-col>
            <b-col
              md="4"
              class="text-center"
            >
              <b-button
                variant="outline-primary"
                size="sm"
                :disabled="isModalUpdatePaymentDetails"
                @click="isModalUpdatePaymentDetails = true"
              >
                {{ $t('Update') }}
              </b-button>
            </b-col>
          </b-row>
          <h5
            v-if="invoices.length"
            class="mb-1 mt-3 mx-0"
          >
            {{ $t('Invoices') }}
          </h5>
          <b-row
            v-if="invoices.length"
            class="mx-0 px-1 py-1"
            style="background-color: #f3f2f7"
          >
            <b-col
              md="4"
              class="px-0"
            >
              <h5 class="text-uppercase">
                {{ $t('Date') }}
              </h5>
            </b-col>
            <b-col
              md="3"
              class="px-0"
            >
              <h5 class="text-uppercase">
                {{ $t('Amount') }}
              </h5>
            </b-col>
            <b-col
              md="2"
              class="px-0"
            >
              <h5 class="text-uppercase">
                {{ $t('Status') }}
              </h5>
            </b-col>
            <b-col
              md="3"
              class="px-0"
            />
          </b-row>
          <b-row
            v-for="invoice in invoices"
            :key="invoice.id"
            align-v="center"
            class="mx-0 py-1 px-1"
          >
            <b-col
              md="4"
              class="px-0"
            >
              {{ getFormattedDate(invoice.created) }}
            </b-col>
            <b-col
              md="3"
              class="px-0"
            >
              {{ getCurrencySymbolByName(invoice.currency) }}{{ invoice.total }}
            </b-col>
            <b-col
              md="2"
              class="px-0"
            >
              <div :class="[{ 'text-danger': invoice.status !== 'Paid' }]">
                {{ invoice.status }}
              </div>
            </b-col>
            <b-col
              md="3"
              class="px-0 text-right"
            >
              <b-link
                :class="[{ 'text-secondary': !invoice.link }]"
                :href="invoice.link ? invoice.link : ''"
                :disabled="!invoice.link"
              >
                {{ $t('Download') }}
              </b-link>
              <!--<span>
                {{ $t('Generating invoice') }}
              </span>-->
            </b-col>
          </b-row>
        </div>
      </template>
    </b-card>
    <modal-stripe
      :visible="isModalStartSubscription"
      :title="startSubscriptionModalTitle"
      :button-text="`${$t('Start Free Trial')}`"
      :display-coupon="true"
      :chosen-plan-monthly-pay="chosenPlanMonthlyPay || 0"
      :chosen-plan-currency-symbol="chosenPlanCurrencySymbol || '$'"
      @cancel="isModalStartSubscription = false"
      @success="startSubscription"
    />
    <modal-stripe
      :visible="isModalUpdatePaymentDetails"
      :title="`${$t('Update Card Information')}`"
      :button-text="`${$t('Update Card')}`"
      :display-coupon="false"
      :chosen-plan-monthly-pay="0"
      :chosen-plan-currency-symbol="'$'"
      @cancel="isModalUpdatePaymentDetails = false"
      @success="updatePaymentDetails"
    />
    <modal-change-plan
      :visible="isModalChangePlan"
      @cancel="isModalChangePlan = false"
      @changePlan="changePlan"
      @cancelSubscription="onCancelSubscription"
    />
    <modal-cancel-subscription
      :visible="isModalCancelSubscription"
      :end-date="currentPeriodEndDate"
      @cancel="isModalCancelSubscription = false"
    />
    <modal-downgrade
      :visible="isModalDowngrade"
      :title="`${downgradeModalTitle}`"
      :application-state="downgradeModalData"
      @cancel="isModalDowngrade = false"
      @changePlan="changePlan"
    />
    <b-modal
      v-model="isModalPlanChanged"
      centered
      hide-header
      hide-footer
    >
      <div class="d-flex flex-column align-items-center text-center pt-2 pb-2">
        <h3 class="mb-0">
          {{ $t('modalPlanChangedTitle') }}
        </h3>
        <h4
          class="mt-1 mb-0 font-weight-normal"
          style="max-width: 80%"
        >
          <i18n path="modalPlanChangedMessage">
            <template v-slot:slot>
              <b>{{ planChangedTo ? $t(planChangedTo.name) : '' }}</b>
            </template>
          </i18n>
        </h4>
        <div class="mt-2 pt-1">
          <b-button
            variant="primary"
            @click="onClickPlanChangedButton"
          >
            {{ $t('modalPlanChangedButtonName') }}
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BSpinner,
  BCard,
  BRow,
  BCol,
  BBadge,
  BButton,
  BLink,
  BModal,
  BTable,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import Plans from '@/components/Plans.vue'
import ModalStripe from '@/components/modal/ModalStripe.vue'
import ModalCancelSubscription from '@/components/modal/ModalCancelSubscription.vue'
import ModalChangePlan from '@/components/modal/ModalChangePlan.vue'
import ModalDowngrade from '@/components/modal/ModalDowngrade.vue'
import mixinFormatter from '@/mixins/formatter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import START_SUBSCRIPTION from '@/gql/mutation/billing/startSubscription.gql'
import UPDATE_PAYMENT_METHOD from '@/gql/mutation/billing/updatePaymentMethod.gql'
import GET_USERS_INVITED_TO_ORGANIZATION from '@/gql/query/getUsersInvitedToOrganization.gql'
import UPGRADE_SUBSCRIPTION from '@/gql/mutation/billing/upgradeSubscription.gql'
import DOWNGRADE_SUBSCRIPTION from '@/gql/mutation/billing/downgradeSubscription.gql'

export default {
  name: 'Billing',
  components: {
    BSpinner,
    BCard,
    BRow,
    BCol,
    BBadge,
    BButton,
    BLink,
    ModalCancelSubscription,
    Plans,
    ModalStripe,
    ModalChangePlan,
    ModalDowngrade,
    BModal,
    BTable,
  },
  mixins: [mixinFormatter],
  data() {
    return {
      subscriptionDetailsLoading: true,
      token: null,

      activePlan: null,
      billingEmailAddress: '',

      isModalStartSubscription: false,
      startSubscriptionModalTitle: '',
      chosenPlan: null,
      chosenPeriod: null,
      chosenPlanMonthlyPay: null,
      chosenPlanCurrencySymbol: null,

      isModalUpdatePaymentDetails: false,
      isModalChangePlan: false,
      isModalCancelSubscription: false,

      isModalPlanChanged: false,
      planChangedTo: null,

      isModalDowngrade: false,
      downgradeModalTitle: this.$t('downgradeToPlan', { name: this.$t('Basic') }),
      downgradeModalData: {
        targetPlan: {},
        targetPeriod: 'yearly',
        users: [],
        menus: [],
        zones: [],
        languages: [],
      },

      isFetching: true,
    }
  },
  computed: {
    ...mapGetters({
      restaurants: 'restaurant/restaurants',
      menus: 'menuManagement/menus',
      zones: 'floorPlan/zones',
      isSubscriptionInactive: 'billing/isSubscriptionInactive',
      isSubscriptionActive: 'billing/isSubscriptionActive',
      isSubscriptionCanceled: 'billing/isSubscriptionCanceled',
      restaurant: 'restaurant/restaurant',
      languages: 'dictionaries/languages',
      currencies: 'dictionaries/currencies',
      isInvoicesFetched: 'billing/isInvoicesFetched',
      subscription: 'billing/subscription',
      invoices: 'billing/invoices',
      billingConfig: 'billing/billingConfig',
      paymentMethods: 'billing/paymentMethods',
    }),
    subscriptionStatus() {
      if (!this.subscription) {
        return ''
      }

      return this.isSubscriptionActive && this.subscription.trialActive
        ? this.$t('Trial')
        : this.$t(this.subscription.status)
    },
    subscriptionStatusBadgeVariant() {
      let result = 'light-dark'

      if (this.subscription.trialActive) {
        result = 'light-info'
      }

      if (!this.subscription.trialActive && this.isSubscriptionActive) {
        result = 'light-success'
      }

      if (this.isSubscriptionInactive) {
        result = 'light-warning'
      }

      if (this.isSubscriptionCanceled) {
        result = 'light-danger'
      }

      return result
    },
    intervalUnit() {
      if (!this.subscription) {
        return ''
      }
      return this.subscription.interval === 'monthly' ? 'month' : 'year'
    },
    currentPeriodEndDate() {
      if (!this.subscription) {
        return ''
      }
      return this.getFormattedDate(this.subscription.currentPeriodEndDate)
    },
    // currentPeriodStartDate() {
    //   return this.getFormattedDate(this.subscription.currentPeriodStartDate)
    // },
    trialPeriodEndDate() {
      return this.getFormattedDate(this.subscription.trialPeriodEndDate)
    },
    monthlyPay() {
      const monthlyPay = this.subscription?.productDetails?.periods?.[this.subscription?.interval]?.monthlyPay
        || this.subscription?.amount
      return this.getFormattedPrice(monthlyPay)
    },
  },
  watch: {
    subscription() {
      this.checkDependencies()
    },
    isInvoicesFetched() {
      this.checkDependencies()
    },
    billingConfig() {
      this.checkDependencies()
    },
    paymentMethods() {
      this.checkDependencies()
    },
    restaurant() {
      if (!this.paymentMethods) {
        this.getPaymentMethods()
      }
    },
  },
  created() {
    this.checkDependencies()
    if (this.restaurant && !this.paymentMethods) {
      this.getPaymentMethods()
    }
  },
  methods: {
    ...mapActions({
      getRestaurants: 'restaurant/getRestaurants',
      getMenus: 'menuManagement/getMenus',
      getMenuItems: 'menuManagement/getMenuItems',
      getZones: 'floorPlan/getZones',
      getSubscription: 'billing/getSubscription',
      getInvoices: 'billing/getInvoices',
      getPaymentMethods: 'billing/getPaymentMethods',
    }),
    getAddonNameToRender(name) {
      if (name === 'Events') {
        return this.$t('addOnsEventsName')
      }
      if (name === 'Orders') {
        return this.$t('addOnsOrdersName')
      }
      if (name === 'Payments') {
        return this.$t('addOnsPaymentsName')
      }
      return name
    },
    checkDependencies() {
      if (this.subscription && this.isInvoicesFetched && this.billingConfig && this.paymentMethods) {
        this.isFetching = false
      }
    },
    getCurrencySymbolByName(currencyName) {
      if (!currencyName) {
        return ''
      }

      const currency = this.currencies.find(i => i.code === currencyName.toUpperCase())
      if (!currency) {
        return ''
      }

      return currency.symbolNative
    },
    selectSubscription(chosenPlanId, period) {
      this.chosenPlan = this.billingConfig.plans.find(i => i.id === chosenPlanId)
      this.chosenPeriod = period
      this.chosenPlanMonthlyPay = this.chosenPlan.periods[this.chosenPeriod].monthlyPay
      this.chosenPlanCurrencySymbol = this.chosenPlan.periods[this.chosenPeriod].currencySymbol

      this.startSubscriptionModalTitle = `${this.$t(this.chosenPlan.name)} ${this.$t('Plan Subscription')}`
      this.isModalStartSubscription = true
    },
    async startSubscription({
      country,
      cardholderName,
      billingEmailAddress,
      token,
      coupon,
    }) {
      try {
        await this.$apollo.mutate({
          mutation: START_SUBSCRIPTION,
          variables: {
            organizationId: this.restaurant.organization.id,
            billingEmailAddress,
            cardholderName,
            qty: this.restaurants.length,
            country,
            stripePriceId: this.chosenPlan.periods[this.chosenPeriod].stripePriceId,
            stripeProductId: this.chosenPlan.periods[this.chosenPeriod].stripeProductId,
            token,
            coupon,
          },
        })

        await Promise.all([
          this.getSubscription(),
          this.getInvoices(),
          this.getPaymentMethods(),
        ])
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: error.graphQLErrors[0].id,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isModalStartSubscription = false
      }
    },
    async updatePaymentDetails({
      country,
      cardholderName,
      billingEmailAddress,
      token,
      // coupon,
    }) {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_PAYMENT_METHOD,
          variables: {
            organizationId: this.restaurant.organization.id,
            country,
            name: cardholderName,
            billingEmailAddress,
            token,
          },
        })

        await Promise.all([
          this.getSubscription(),
          this.getInvoices(),
          this.getPaymentMethods(),
        ])
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: error.graphQLErrors[0].id,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isModalUpdatePaymentDetails = false
      }
    },
    onCancelSubscription() {
      this.isModalChangePlan = false
      this.isModalCancelSubscription = true
    },
    async changePlan(productId, period, downgradeOptions) {
      this.isModalChangePlan = false

      const newPlan = this.billingConfig.plans.find(i => i.id === productId)
      const { stripeProductId, stripePriceId } = newPlan.periods[period]

      if (this.subscription.productDetails.id > newPlan.id) {
        if (!downgradeOptions) {
          const responses = await Promise.all([
            this.$apollo.query({
              query: GET_USERS_INVITED_TO_ORGANIZATION,
              variables: {
                organizationId: this.restaurant.organization.id,
              },
            }),
            this.getMenus(),
            this.getZones(),
          ])
          this.downgradeModalData = {
            targetPlan: newPlan,
            targetPeriod: period,
            users: responses[0].data.getUsersInvitedToOrganization,
            menus: this.menus,
            zones: this.zones,
            languages: this.restaurant.translationLanguages.map(i => this.languages.find(j => j.shortName === i)),
          }
          this.downgradeModalTitle = this.$t('downgradeToPlan', { name: this.$t(newPlan.name) })
          this.isModalDowngrade = true
        } else {
          this.isModalDowngrade = false
          await this.changeSubscription(DOWNGRADE_SUBSCRIPTION, stripePriceId, stripeProductId, downgradeOptions, newPlan)
        }
      } else {
        await this.changeSubscription(UPGRADE_SUBSCRIPTION, stripePriceId, stripeProductId, {}, newPlan)
      }
    },
    async changeSubscription(mutation, stripePriceId, stripeProductId, additionalVariables, newPlan) {
      this.isFetching = true

      try {
        await this.$apollo.mutate({
          mutation,
          variables: {
            organizationId: this.restaurant.organization.id,
            stripePriceId,
            stripeProductId,
            ...additionalVariables,
          },
        })

        await Promise.all([
          this.getSubscription(),
          this.getInvoices(),

          this.getRestaurants(),
          this.getMenus(),
          this.getMenuItems(),
          this.getZones(),
        ])

        this.planChangedTo = newPlan
        this.isModalPlanChanged = true
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: Array.isArray(error.graphQLErrors[0].message)
              ? error.graphQLErrors[0].message[0]
              : error.graphQLErrors[0].message,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isFetching = false
      }
    },
    getPlanNameAfterSwitchByStipeProductId() {
      const { productDetails } = this.subscription.upcomingSubscription
      if (productDetails && productDetails.name) {
        return productDetails.name
      }

      const stripeProductId = this.subscription.upcomingSubscription.product
      if (stripeProductId) {
        const plan = this.billingConfig.plans
          .find(i => Object.values(i.periods).find(j => j.stripeProductId === stripeProductId))
        if (plan) {
          return plan.name
        }
      }

      return ''
    },
    onClickPlanChangedButton() {
      this.isModalPlanChanged = false
      this.planChangedTo = null
    },
  },
}
</script>
