<template>
  <b-modal
    :visible="visible"
    :title="$t('Change Plan')"
    centered
    hide-footer
    size="lg"
    @hide="$emit('cancel')"
  >
    <b-form
      class="pt-1 pb-1"
      @submit.prevent
    >
      <plans
        :display-plans-description="true"
        @selectSubscription="changePlan"
        @cancelSubscription="$emit('cancelSubscription')"
        @cancel="$emit('cancel')"
      />

      <div class="mt-2 d-flex justify-content-end">
        <b-button
          variant="outline-primary"
          @click="$emit('cancel')"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import {
  BModal,
  BButton,
  BForm,
} from 'bootstrap-vue'
import Plans from '@/components/Plans.vue'

export default {
  name: 'ModalChangePlan',
  components: {
    BModal,
    BButton,
    Plans,
    BForm,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  methods: {
    changePlan(productId, period) {
      this.$emit('changePlan', productId, period)
    },
  },
}
</script>
